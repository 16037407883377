import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

const classType = {
    viewOptionsLeft: {
        class: 'menu--bottomLeft',
        align: 'end',
    },
    viewOptionsRight: {
        class: 'menu--bottomRight',
        align: 'start',
    },
    bottom: {
        class: '',
        align: '',
    }
}

const MenuListing = ({ className, type, children, align, menuButton, menuClassName, menuStyles, ...rest }) => {
    return (
        <Menu menuClassName={menuClassName} align={align || (classType[type] ? classType[type].align : 'start')} className={`menu--default menu--options ${className} ${classType[type] ? classType[type].class : ''}`} menuButton={menuButton} transition menuStyles={menuStyles} {...rest} >
            {children}
        </Menu>
    );
}

MenuListing.defaultProps = {
    className: '',
    type: 'viewOptions',
    menuClassName: ''
}

MenuListing.propTypes = {
    className: PropTypes.string,
    /**
     * Which direction to show list popup.
     */
    align: PropTypes.oneOf(['start', 'center', 'end']),
    type: PropTypes.oneOf(['viewOptionsLeft', 'viewOptionsRight', 'bottom']),
    menuClassName: PropTypes.string
}

export default MenuListing
